import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseButton from "src/components/Base/BaseButton"

const ContactForm = ({ className }) => {
  const [observed, setObserved] = useState(false)
  const [state, setState] = useState({})
  const [buttonLabel, setButtonLabel] = useState("Send message")

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  function handleSubmit(event) {
    event.preventDefault()
    const form = event.target

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => console.log(error))

    setButtonLabel("Sending…")
  }

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <Wrapper
      name="contact"
      method="post"
      action="/contact-success"
      data-netlify="true"
      data-netlify-honeypot="first-name"
      className={className + (observed ? " -observed" : "")}
      onSubmit={handleSubmit}
    >
      <FieldWrapper>
        <Input
          name="name"
          placeholder="Your name"
          required
          onChange={handleChange}
          aria-label="Your name"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          name="organisation"
          placeholder="Organisation"
          onChange={handleChange}
          aria-label="Organisation"
        />
        <OptionalMark>optional</OptionalMark>
      </FieldWrapper>
      <FieldWrapper>
        <Input
          type="email"
          name="email"
          placeholder="Email address"
          required
          onChange={handleChange}
          aria-label="Email address"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          name="subject"
          placeholder="Subject"
          required
          onChange={handleChange}
          aria-label="Subject"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Textarea
          as="textarea"
          name="message"
          placeholder="Message"
          required
          onChange={handleChange}
          aria-label="Message"
        />
      </FieldWrapper>
      <FieldWrapper>
        <Button type="submit" label={buttonLabel} />
      </FieldWrapper>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      {/* Honeypot */}
      <FieldWrapper hidden>
        <Input name="first-name" onChange={handleChange} />
      </FieldWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const FieldWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:nth-child(1) {
    transition-delay: 0.15s;
  }

  &:nth-child(2) {
    transition-delay: 0.25s;
  }

  &:nth-child(3) {
    transition-delay: 0.35s;
  }

  &:nth-child(4) {
    transition-delay: 0.45s;
  }

  &:nth-child(5) {
    transition-delay: 0.55s;
  }

  &:nth-child(6) {
    transition-delay: 0.65s;
  }

  ${Wrapper}:not(.-observed) & {
    opacity: 0;
    transform: translateY(20px);
  }
`

const Input = styled.input`
  width: 100%;
  padding: 12px 25px 11px;
  border: 1px solid #cfdbdb;
  border-radius: 3px;
  color: #000;
  transition: 0.15s;
  transition-property: border-color, box-shadow;

  &::placeholder {
    color: rgba(116, 115, 125, 0.75);
  }

  &:focus {
    border-color: #3ab6da;
    box-shadow: 0 0 30px hsla(179, 64%, 58%, 0.1),
      0 0 5px hsla(179, 64%, 58%, 0.35);

    &::placeholder {
      color: rgba(116, 115, 125, 0.5);
    }
  }

  ${media.tablet} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const Textarea = styled(Input)`
  display: block;
  height: 250px;
  resize: vertical;
`

const OptionalMark = styled.span`
  position: absolute;
  top: 0;
  right: 25px;
  line-height: 50px;
  font-size: 13px;
  color: #4D5B5B;
  pointer-events: none;
`

const Button = styled(BaseButton)``

export default ContactForm
